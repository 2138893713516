var Loader = (function($){

  var _openLoader = function(){
    $('body').addClass('is-loader-working');
  };

  var _closeLoader = function(){
    $('body').removeClass('is-loader-working');
  };

  return {
    init: function(){
      $(window).load(function(){
        _closeLoader();
      });
    },
    open: _openLoader,
    close: _closeLoader
  };

})(jQuery);
