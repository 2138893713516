var Layer = (function($){

  // Cache some variables
  var isOpened =    false;
  var $body =       $('body');
  var $layer =      $('#layer');
  var $closeLayer = $('#layer-close');
  var source      = $('#layer-template').html();

  var _openLayer = function( target ) {

    if(typeof(target) === 'undefined') {
      target = '#layer';
    }
    $('body').addClass('has-layer-opened');
    $(target).addClass('is-opened');
    isOpened = true;

  };

  var _closeLayer = function( target ) {

    $body.removeClass('has-layer-opened');
    $(target).removeClass('is-opened');
    isOpened = false;

  };

  var _openLayerLoader = function(){

    $layer.addClass('has-loader');

  };

  var _closeLayerLoader = function(){

    $layer.removeClass('has-loader');

  };

  var _events = function( target ) {

    // Get a quote
    $('.js-request-quote').on('click', function(ev){
      ev.preventDefault();

      _openLayer();
      _openLayerLoader();

      $.ajax({
        type: 'post',
        url: arcadia.ajaxurl,
        data: {
          action: 'layer_quote',
          nonce: $(this).attr('data-nonce')
        },
        cache: true,
        dataType: 'json',
        success: function(response){
          if( response.success ) {
            var template = Handlebars.compile(source);
            $layer.html(template(response.data));
            Form.init();
            _closeLayerLoader();
            _openLayer();
          }
        }
      });

    });

    // Mobile "More" menu item
    $('#nav-more').on('click', function(ev){
      ev.preventDefault();

      if(isOpened) {
        _closeLayer();
      } else {
        _openLayer();
      }

      _openLayerLoader();

      $.ajax({
        type: 'post',
        url: arcadia.ajaxurl,
        data: {
          action: 'layer_nav',
          nonce: $(this).attr('data-nonce')
        },
        cache: true,
        dataType: 'json',
        success: function(response){
          if( response.success ) {
            var template = Handlebars.compile(source);
            $layer.html(template(response.data));
            _closeLayerLoader();
          }
        }
      });

    });

    // "Search" menu item
    $('#nav-search').on('click', function(ev){
      ev.preventDefault();

      if(isOpened) {

        _closeLayer( '#layer-search' );

      } else {

        _openLayer( '#layer-search' );

      }

    });

    // "Tech" modal window
    $('#tech-download').on('click', function(ev){
      ev.preventDefault();

      if(isOpened) {

        _closeLayer( '#layer-tech' );

      } else {

        _openLayer( '#layer-tech' );

      }

    });

    // Layer is opened. Close layer with top right close button
    $(document).on('click', '.js-layer-close', function(ev){
      ev.preventDefault();
      var target = $(this).parents('.layer');
      _closeLayer( target );
    });

  };

  return {
    init: _events,
    open: _openLayer,
    close: _closeLayer
  };

})(jQuery);
