var Btn = {
	working: function( obj ) {
		obj.addClass('-working').prop('disabled', true);
	},
	error: function( obj ){
		obj.removeClass('-working').addClass('-error');
	},
	success: function( obj ){
		obj.removeClass('-working').addClass('-success');
		setTimeout(function(){
			Btn.reset( obj );
		}, 4000);
	},
	warning: function( obj ){
		obj.removeClass('-working').addClass('-warning');
	},
	reset: function( obj ){
		obj.removeClass('-working -warning -error -success').prop('disabled', false);
	}
};

var Form = (function($){

  // Cache some general jQuery object
  var $form, $submit, $response;

  function _inputEvents() {

    var $el = $('.form__control');

    // Detect focus
    $el.on('focus', function(){
      $(this).parent('.form__field').addClass('focus');
    });

    $el.on('focusout', function(){
      $(this).parent('.form__field').removeClass('focus');
    });

    // Detect typing
    $el.on('keyup', function(){
      var num = $(this).val().length;

      if (num > 0) {
        $(this).parent('.form__field').addClass('typed');
      } else {
        $(this).parent('.form__field').removeClass('typed');
      }
    });

    // Autosize textarea
    $('textarea').on('keydown', function(){
      var element = this;
      element.style.height = "0";

      var offset = element.offsetHeight;

      element.style.height = element.scrollHeight + offset + "px";
    });

    // Disable company field on quote form for "private users"
    $('input[name="type"]').change(function() {
      if( this.value === 'Costruttore' || this.value === 'Rivenditore' ) {

        $('#form-group-company').removeClass('-disabled');

      } else if (this.value === 'Privato') {

        var $input = $('#form-group-company').find('input[type="text"]');

        if( $input.val() ) {
          $('#form-group-company').find('.form__field').removeClass('typed');
          $input.val('');
        }

       $('#form-group-company').addClass('-disabled');

     }
    });

  }

  function _submit() {
    // Init parsley validation
    $form.parsley({
      successClass: "has-success",
      errorClass: "has-error",
      classHandler: function(el) {
        return el.$element.parents(".form__group");
      },
    });

    // Form submit
    $form.submit(function(ev){

      ev.preventDefault();

      if ( $form.parsley().isValid() ) {

        Btn.working($submit);

        var formData = $form.serialize() + '&action=' +  'request_info';

        $.ajax({
          type: 'post',
          url: arcadia.ajaxurl,
          data: formData,
          dataType: 'json',
          success: function(response){
            if( response.success ) {

              // Reset Form
              $form.get(0).reset();

              Btn.success($submit);
              $response.find('#form-response-content').html(response.data.message);
              $form.addClass('has-response');

            } else {

              Btn.error($submit);
              $response.find('#form-response-content').html(response.data.message);
            }
          }
        });
      }
    });
  }

  var init = function() {

    $form     = $('.form--contact');
    $submit   = $form.find('button[type="submit"]');
    $response = $form.find('.form-response-js');

    _inputEvents();
    _submit();

    /*jshint multistr: true */
    $('#filer').filer({
      limit: 1,
      maxSize: 10,
      showThumbs: true,
      templates: {
        box: '<ul class="jFiler-items-list jFiler-items-grid"></ul>',
        item: '<li class="jFiler-item">\
            <div class="jFiler-item-container">\
                <div class="jFiler-item-inner">\
                    <div class="jFiler-item-thumb">\
                        <div class="jFiler-item-status"></div>\
                        <div class="jFiler-item-info">\
                            <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name | limitTo: 25}}</b></span>\
                            <span class="jFiler-item-others">{{fi-size2}}</span>\
                        </div>\
                        {{fi-image}}\
                    </div>\
                    <div class="jFiler-item-assets jFiler-row">\
                        <ul class="list-unstyled">\
                            <li>{{fi-progressBar}}</li>\
                        </ul>\
                        <ul class="list-unstyled">\
                            <li><a class="icon icon-delete jFiler-item-trash-action"></a></li>\
                        </ul>\
                    </div>\
                </div>\
            </div>\
        </li>',
        itemAppend: '<li class="jFiler-item">\
            <div class="jFiler-item-container">\
                <div class="jFiler-item-inner">\
                    <div class="jFiler-item-thumb">\
                        <div class="jFiler-item-status"></div>\
                        <div class="jFiler-item-info">\
                            <span class="jFiler-item-title"><b title="{{fi-name}}">{{fi-name | limitTo: 25}}</b></span>\
                            <span class="jFiler-item-others">{{fi-size2}}</span>\
                        </div>\
                        {{fi-image}}\
                    </div>\
                    <div class="jFiler-item-assets jFiler-row">\
                        <ul class="list-unstyled">\
                            <li><span class="jFiler-item-others">{{fi-icon}}</span></li>\
                        </ul>\
                        <ul class="list-unstyled">\
                            <li><a class="icon icon-delete jFiler-item-trash-action"></a></li>\
                        </ul>\
                    </div>\
                </div>\
            </div>\
        </li>',
        progressBar: '<div class="bar"></div>',
        itemAppendToEnd: false,
        removeConfirmation: true
      },
      captions: {
        button: 'Seleziona file',
        feedback: 'Allegato (10mb max)',
        feedback2: 'file selezionato'
      },
    uploadFile: {
        url: arcadia.ajaxurl,
        data: {
          action: 'upload_file'
        },
        type: 'POST',
        enctype: 'multipart/form-data',
        beforeSend: function(){},
        success: function(response, el){
            console.log(response);
            var parent = el.find(".jFiler-jProgressBar").parent();
            el.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                $("<div class=\"jFiler-item-others text-success\"><i class=\"icon icon-check\"></i></div>").hide().appendTo(parent).fadeIn("slow");
            });
            el.append('<input type="hidden" name="attachment" value="'+response.data.file+'"/>');
            el.append('<input type="hidden" name="attachment_url" value="'+response.data.url+'"/>');
        },
        error: function(el){
            var parent = el.find(".jFiler-jProgressBar").parent();
            el.find(".jFiler-jProgressBar").fadeOut("slow", function(){
                $("<div class=\"jFiler-item-others text-error\"><i class=\"icon icon-close\"></i></div>").hide().appendTo(parent).fadeIn("slow");
            });
        }
    }
    });
  };

  // API
  return {
    init: init
  };

})(jQuery);

var ProtectedDownload = (function($){

  var _events = function() {

    $('.js-download-file').popover({
      html: true,
      placement: 'top',
      container: $('#js-popover-container'),
      content: function() {
        return $('#js-protected-download').html();
      },
    });

    $('.js-download-file').on('inserted.bs.popover', function (e) {
      $container = $('#js-popover-container');
      $form = $container.find('form');
      $submit = $form.find('button[type="submit"]');
      $response = $container.find('.js-form-response');

      $container.removeClass('has-response');

      $submit.on('click', function(ev){

        ev.preventDefault();
        if ( $form.get(0).checkValidity() ) {
          Btn.working($submit);

          var formData = $form.serialize() + '&action=' +  'protected_download_action';

          $.ajax({
            type: 'post',
            url: arcadia.ajaxurl,
            data: formData,
            dataType: 'json',
            success: function(response){
              if( response.success ) {
                // Reset Form
                $form.get(0).reset();
                Btn.success($submit);
                $response.html(response.data.message);
                $container.addClass('has-response');
                var fileLink = $('<a class="hidden" href="'+response.data.file.url+'"></a>');
                $response.append(fileLink);
                setTimeout(function(){
                  fileLink.get(0).click();
                }, 2000);
              } else {
                Btn.error($submit);
                $response.html(response.data.message);
                $container.addClass('has-response');
              }
            }
          });
        }
      });
    });
  };

  var init = function() {
    _events();
  };

  return {
    init: init
  };
})(jQuery);
