/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Layzr Init
        var layzr = new Layzr();

        // swiper
        var mySwiper = new Swiper('.swiper-container', {
          autoplay: {
             delay: 4000,
           },
          pagination: {
            el: '.swiper-pagination',
          },
        });

        // Avoid 300ms lag click on touch devices
        FastClick.attach(document.body);

        // Page loader
        Loader.init();

        // Init Layer fetures
        Layer.init();

        // Init OWL Carousel
        var $carousel = $(".owl-carousel");
        $carousel.owlCarousel({
          loop: true,
          autoplay: true,
          dots: false,
          navContainerClass: 'carousel__nav',
          navClass: ['carousel__control-prev icon icon-before', 'carousel__control-next icon icon-next'],
          navText: ['', ''],
          dotClass: 'carousel__dot',
          dotsClass: 'carousel__pager',
          lazyLoad: true,
          margin: 0,
          responsiveClass:true,
          responsive:{
              320:{
                  items:1,
                  nav:true
              },
              414:{
                  items:2,
                  nav:true
              },
              768:{
                  items:3,
                  nav:true
              },
              992:{
                  items:4,
                  nav:false
              },
              1200:{
                  items:5,
                  nav:true,
                  loop:false
              }
          }
        });

        function toggleMore(e) {
          $(e.target)
          .prev('.entry-content__row-title')
          .find('.icon')
          .toggleClass('icon-collapse')
          .addClass('icon-expande');
        }

        function toggleLess(e) {
          $(e.target)
          .prev('.entry-content__row-title')
          .find('.icon')
          .toggleClass('icon-expande')
          .addClass('icon-collapse');
        }

        $('.entry-content__row-content').on('hidden.bs.collapse', toggleMore);
        $('.entry-content__row-content').on('shown.bs.collapse', toggleLess);

        // Scroll Reveal Init
        window.sr = new ScrollReveal();
        sr.reveal('.sr-from-top', {
          wait: 200,
          origin: 'top',
          mobile: false
        });
        sr.reveal('.sr-from-left', {
          wait: 200,
          origin: 'left',
          mobile: false
        });
        sr.reveal('.sr-from-right', {
          wait: 200,
          origin: 'right',
          mobile: false
        });
        sr.reveal('.sr-from-bottom', {
          wait: 200,
          origin: 'bottom',
          mobile: false
        });

        //
        // Smooth Go To
        //
        $('a.smooth-scroll').on('click',function(ev) {
          ev.preventDefault();

          var target = this.hash;
          var $target = $(this.hash);

          $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 150
          }, 900, 'swing', function () {
            window.location.hash = target;
          });
        });

        //
        // Magnific Popup Init
        //
        $('.image-popup, .entry-content a[href$=".jpg"], .entry-content a[href$=".jpeg"], .entry-content a[href$=".gif"], .entry-content a[href$=".png"]').not('.js-product-section-gallery a').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
          },
          closeOnContentClick: true,
          closeBtnInside: false,
          fixedContentPos: true,
          mainClass: 'mfp-with-zoom',
          image: {
            verticalFit: true,
            titleSrc: function(item) {
              var caption = item.el[0].nextSibling && item.el[0].nextSibling.innerHTML;
              var title = item.el.attr('title') || caption || '';
              return title + '<small>by Arcarossa - Gruppo Penta</small>';
            }
          },
          zoom: {
            enabled: true,
            duration: 300
          }
        });

        $('.js-product-section-gallery').each(function(){
          $(this).find('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".gif"], a[href$=".png"]').magnificPopup({
            type: 'image',
            gallery: {
              enabled: true,
              navigateByImgClick: true,
              preload: [0,1]
            },
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            mainClass: 'mfp-with-zoom',
            image: {
              verticalFit: true,
              titleSrc: function(item) {
                return $(item.el).find('img').attr('title') + '<small>by Arcarossa - Gruppo Penta</small>';
              }
            },
            zoom: {
              enabled: true,
              duration: 300
            }
          });
        });

        $('.popup-gallery, .gallery').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
              return item.el.attr('title') + '<small>by Arcarossa - Gruppo Penta</small>';
            }
          }
        });

        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          iframe: {
            patterns: {
              youtube_short: {
                index: 'youtu.be/',
                id: 'youtu.be/',
                src: '//www.youtube.com/embed/%id%?autoplay=1'
              }
            }
          }
        });

        StickyElements.header();

        $(".js-filter").on('click', '.dropdown-menu li a', function(){
          var $filter = $(this).parents('.js-filter');
          $filter.find(".dropdown-toggle").text($(this).text());
          $filter.find(".dropdown-toggle").val($(this).text());
        });
      },
    },
    'single_post': {
      init: function() {
        StickyElements.sidebar();
      }
    },
    'single_wall': {
      init: function() {
        StickyElements.toolbar();
        ProtectedDownload.init();
      }
    },
    'single_furniture': {
      init: function() {
        StickyElements.toolbar();
        ProtectedDownload.init();
      }
    },
    'single_rolling': {
      init: function() {
        StickyElements.toolbar();
        ProtectedDownload.init();
      }
    },
    'single_seat': {
      init: function() {
        StickyElements.toolbar();
        ProtectedDownload.init();
      }
    },
    'page_template_template_contact': {
      init: function(){
        Form.init();
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
