var StickyElements = (function($){

  var offset_top = 0;

  if( $('body').hasClass('admin-bar') ) {
    offset_top = 32;
  }

  // Sticky Header
  initStickyHeader = function(){
    enquire.register("screen and (min-width: 768px)", {
      match : function() {
        $("#banner").stick_in_parent({
          parent: 'body',
          offset_top: offset_top
        });
      }
    });
  };

  // Sticky Sidebar
  initStickySidebar = function() {
    enquire.register("screen and (min-width: 768px)", {
      match : function() {
        $("#sidebar").stick_in_parent({
          parent: 'body',
          offset_top: (offset_top + 60)
        });
      }
    });

  };

  // Sticky Toolbar
  initStickyToolbar = function(){

    enquire.register("screen and (min-width: 768px)", {
      match : function() {
        $("#toolbar").stick_in_parent({
          parent: 'body',
          offset_top: (offset_top + 49)
        });
      }
    });

  };

  // API
  return {
    header: initStickyHeader,
    sidebar: initStickySidebar,
    toolbar: initStickyToolbar
  };

})(jQuery);
