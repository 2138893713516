var CookieLaw = (function($){

  var $banner      = $('#cookielaw');
  var $bannerClose = $('#cookielaw-close');

  var _setCookie = function( value ){
    Cookies.set('cookielaw', value, { expires: 365, path: '/' });
  };

  var _showBanner = function(){
    $banner.removeClass('hidden');
  };

  var _closeBanner = function(){
    _setCookie( 'true' );
    $banner.addClass('hidden');
    $banner.remove();
  };

  var _events = function(){

    // Close button click
    $bannerClose.on('click', function(){
      _closeBanner();
    });

    // Page scroll
    $(window).scroll(function () {

      var scroll = $(window).scrollTop();

      if (scroll > 10 ) {
        _closeBanner();
      }

    });

  };

  var _init =  function(){

    if( !Cookies.get('cookielaw') ) {
      _setCookie( 'false' );
    }

    if( Cookies.get('cookielaw') === 'false' ) {
      _showBanner();
    } else {
      _closeBanner();
    }

    _events();
  };

  return {
    init: _init
  };

})(jQuery);
